import { Page } from "../Page";
import { Grid } from "@mui/material";
import { ActiveUsersGrid } from "./ActiveUsers/ActiveUsersGrid";
import { TranslationsGrid } from "./Translations/TranslationsGrid";
import { MeasurementsGrid } from "./Measurements/MeasurementsGrid";
import { UsersByRoleGrid } from "./UsersByRole/UsersByRoleGrid";
import { useApi } from "@doctomatic/sdk/build/Api";
import { processError } from "../../../App/errorToast";
import { useNavigate, useLocation } from "react-router-dom";
import {
  GetActiveUsersByDayResponseDto,
  GetCountUsersByRoleResponseDto,
  GetReadDeviceByDayResponseDto,
  GetSankeyGraphResponseData,
  GetTranslateByDayResponseDto,
} from "@doctomatic/sdk/build/dto/CmsDashboard/CmsDashboard";
import { SelectCompany } from "./selectCompany";
import { Box, InputLabel, Switch, TextField } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { SelectDate } from "../../DatePicker/selectDate";
import moment from "moment";
import { MeasurementsGraphic } from "./Measurements/MeasurementsGraphic";
import { SelectDevice } from "./Measurements/selectDevice";
import { SankeyGraph } from "./SankeyGraph/SankeyGraph";
import { DataRange } from "@doctomatic/sdk/build/utils/query/filter.interface";
import { debounce } from "lodash";
import { addDays } from "date-fns";
interface DashboardProps {
  selectedCompany?: number;
}
interface LocationProps {
  state: unknown;
  companyToSelect: number;
}

const Dashboard = ({
  selectedCompany = 0,
}: DashboardProps): React.ReactElement => {
  const history = useNavigate();
  const location = useLocation();
  const state = location.state as LocationProps;
  const params = new URLSearchParams(location.search);
  const [companyParam, setCompanyParam] = useState<string | null>(
    params.get("company")
  );
  const companyToSelect = state && state.companyToSelect;
  const [companyId, setCompanyId] = useState<string>(
    companyToSelect ? companyToSelect.toString() : selectedCompany.toString()
  );
  const [dateRange, setDateRange] = useState<DataRange<string>>(); // Query date
  const defaultStartDate = addDays(new Date(), -30);
  const defaultEndDate = new Date();
  const [date, setDate] = useState<[Date | null, Date | null]>([defaultStartDate, defaultEndDate]); // Date picker

  const [deviceId, setDeviceId] = useState<number | undefined>();
  const [timeZone, setTimeZone] = useState<number | undefined>();
  const [readDeviceRange, setReadDeviceRange] = useState<DataRange<number>>();
  const [readDeviceStart, setReadDeviceStart] = useState<number>();
  const [readDeviceEnd, setReadDeviceEnd] = useState<number>();
  const [takeById, setTakeById] = useState<number>();
  const [patientId, setPatientId] = useState<number>();
  const [traces, setTraces] = useState<GetSankeyGraphResponseData[]>([]);
  const [patientsDeleted, setPatientsDeleted] = useState<boolean>(false);

  const [showExternalReadDevice, setShowExternalReadDevice] =
    useState<boolean>(false);

  const { useCmsDashboard, useSankeyGraph, logout } = useApi();
  const {
    response: countAllUsers,
    countUsersByRole,
    activeUsersByDay,
    readDeviceByDay,
    translateByDay,
  } = useCmsDashboard(
    +companyId,
    dateRange?.start,
    dateRange?.end,
    deviceId,
    timeZone,
    true,
    patientsDeleted,
    showExternalReadDevice,
    readDeviceRange?.start,
    readDeviceRange?.end,
    processError(logout, history)
  );

  const { getData } = useSankeyGraph(processError(logout, history));
  const AllUsers = countAllUsers?.data?.totalCount;
  const usersByRole = countUsersByRole?.response?.data;
  const activeUsers = activeUsersByDay?.response?.data;
  const measurements = readDeviceByDay?.response?.data;
  const translations = translateByDay?.response?.data;

  useEffect(() => {
    if (date[0] && date[1]) {
      setDateRange({
        start: moment(date[0])
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        end: moment(date[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      });
    } else {
      setDateRange(undefined);
    }
  }, [date]);

  // get time zone
  useEffect(() => {
    const timeZoneOffsetHours = moment().utcOffset() / 60;
    setTimeZone(timeZoneOffsetHours);
  }, []);

  useEffect(() => {
    const fetchSankeyData = async () => {
      try {
        const response = await getData(
          +companyId,
          deviceId,
          timeZone,
          takeById,
          patientId,
          dateRange,
          readDeviceRange
        );
        if (response.data) {
          setTraces(response.data);
        }
      } catch (err: any) {}
    };

    fetchSankeyData();
  }, [
    companyId,
    deviceId,
    timeZone,
    takeById,
    patientId,
    dateRange,
    readDeviceRange,
  ]);

  useEffect(() => {
    if (readDeviceStart && readDeviceEnd) {
      setReadDeviceRange({ start: readDeviceStart, end: readDeviceEnd });
    } else {
      setReadDeviceRange(undefined);
    }
  }, [readDeviceStart, readDeviceEnd]);

  const debouncedSetSearch = useCallback(debounce(setReadDeviceEnd, 1000), []);

  const handleDeviceEnd = (readDeviceEnd: number) => {
    debouncedSetSearch(readDeviceEnd);
  };

  // add the selector of companys and the datepicker top of the page
  const buttons = (
    <Grid
      container
      spacing={2}
      alignItems="center"
      style={{ justifyContent: "center", marginBottom: 20 }}
    >
      <Grid item>
        <SelectDate date={date} setDate={setDate} />
      </Grid>
      <Grid item>
        <SelectCompany
          companyId={companyId}
          companyParam={companyParam}
          setCompanyId={setCompanyId}
        />
      </Grid>

      <Grid item>
        <SelectDevice
          deviceId={deviceId}
          setDeviceId={setDeviceId}
          whiteBg={false}
        />
      </Grid>
      <Grid item>
        <TextField
          id="fromReadDevice"
          label="Desde readDeviceId"
          type="number"
          onChange={(event) =>
            setReadDeviceStart(event.target.value as unknown as number)
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          id="toReadDevice"
          label="Hasta readDeviceId"
          type="number"
          onChange={(event) =>
            handleDeviceEnd(event.target.value as unknown as number)
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          id="patientId"
          label="patientId"
          type="number"
          onChange={(event) =>
            setPatientId(event.target.value as unknown as number)
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          id="takeById"
          label="takeById"
          type="number"
          onChange={(event) =>
            setTakeById(event.target.value as unknown as number)
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item>
        <InputLabel id="display-patients-deleted">
          Mostrar datos pacientes eliminados
        </InputLabel>
        <Switch
          checked={patientsDeleted}
          onChange={(event) => setPatientsDeleted(event.target.checked)}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Grid>
    </Grid>
  );

  return (
    <Page
      title={"Total de Usuarios: " + AllUsers}
      buttons={buttons}
      centerTitle
    >
      <Grid
        container
        spacing={1}
        columns={12}
        style={{ backgroundColor: "white", overflowY: "auto" }}
      >
        {/* MeasurementsGrid graphic*/}
        <Grid item xs={12} lg={6}>
          <MeasurementsGraphic
            measurements={measurements as GetReadDeviceByDayResponseDto[]}
            deviceId={deviceId}
            showExternalReadDevice={showExternalReadDevice}
            setShowExternalReadDevice={setShowExternalReadDevice}
            companyId={+companyId}
            dateRange={dateRange}
            readDeviceRange={readDeviceRange}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <SankeyGraph traces={traces as GetSankeyGraphResponseData[]} />
        </Grid>

        <Grid item xs={12} lg={6}>
          <ActiveUsersGrid
            activeUsersByDay={activeUsers as GetActiveUsersByDayResponseDto[]}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <MeasurementsGrid
            measurements={measurements as GetReadDeviceByDayResponseDto[]}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <TranslationsGrid
            translations={translations as GetTranslateByDayResponseDto[]}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <UsersByRoleGrid
            countUsersByRole={usersByRole as GetCountUsersByRoleResponseDto[]}
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export { Dashboard };
