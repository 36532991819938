import {
  ItemReadDeviceResponse,
  ReadDeviceOnlyImageResponse,
} from "@doctomatic/sdk/build/dto/CmsDashboard/CmsDashboard";
import {
  GridColumns,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import {
  ExtendedItemReadDeviceDataRow,
  ItemReadDeviceDataRow,
} from "./ReadDeviceViewerDataRow";
import moment from "moment";
import { Button, Tooltip, Typography } from "@mui/material";
import BoltIcon from "@mui/icons-material/Bolt";
import SignLanguageIcon from "@mui/icons-material/SignLanguage";
import { TranslationStatus } from "@doctomatic/sdk/build/dto/TranslateImage";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { ResponseApi } from "@doctomatic/sdk/build/global";
import { useEffect, useState } from "react";
import { SING_UNIT_NOT_SHOW } from "@doctomatic/constants/dist/constants";

const euroValue = 0.93;
const inputTokenPrice = 5 / 1e6 / euroValue;
const outputTokenPrice = 15 / 1e6 / euroValue;

interface ImageCellProp {
  id: number;
  getImage?: (id: number) => Promise<ResponseApi<ReadDeviceOnlyImageResponse>>;
}

const ImageCell = ({ id, getImage }: ImageCellProp) => {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      if (getImage) {
        const response = await getImage(id);

        if (response && response.data && response.data.imageUrl) {
          setImageUrl(response.data.imageUrl);
        } else {
          setImageUrl("");
        }
      }
    };

    fetchImage();
  }, [id, getImage]);

  return (
    <div>
      {imageUrl ? (
        <img height={50} width={50} src={imageUrl} alt="Device" />
      ) : (
        <p>Cargando...</p>
      )}
    </div>
  );
};

export const ReadDeviceViewerGridColumns = (
  showReadDeviceModal: (item: ItemReadDeviceResponse) => void,
  showReadDeviceDebugModal: (item: ItemReadDeviceResponse) => void,
  //showSankeyGraphModal: (item: ItemReadDeviceResponse) => void,
  extendedData?: boolean,
  externalAiResponse?: boolean,
  internalAiResponse?: boolean,
  showChoicesModal?: (choices: string[]) => void,
  getImage?: (id: number) => Promise<ResponseApi<ReadDeviceOnlyImageResponse>>,
  showExternal?: boolean
): GridColumns<ItemReadDeviceDataRow | ExtendedItemReadDeviceDataRow> => {

  const { t } = useTranslation();

  let gridColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ReadDeviceId",
      width: 150,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.id,
      renderCell: (params: { row: ItemReadDeviceDataRow }) => {
        return <p>{params.row.id}</p>;
      },
    },
    {
      field: "type",
      headerName: "Tipo",
      width: 200,
      sortable: false,
      hide: !showExternal,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.type_read_device,
      renderCell: (params: { row: ItemReadDeviceDataRow }) => {
        return <p>{params.row.type_read_device}</p>;
      },
    },
    {
      field: "device",
      headerName: "Dispositivo",
      width: 200,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.device,
      renderCell: (params: { row: ItemReadDeviceDataRow }) => {
        return <p>{t(params.row.device)}</p>;
      },
    },
    {
      field: "created_at",
      headerName: "Creación",
      width: 150,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.created_at,
      renderCell: (params: { row: ItemReadDeviceDataRow }) => {
        return (
          <p>{moment(params.row.created_at).format("DD-MM-yyyy HH:mm:ss")}</p>
        );
      },
    },
    {
      field: "image",
      headerName: "Imagen",
      width: 130,
      sortable: false,
      renderCell: (params: { row: ItemReadDeviceDataRow }) =>
        params.row.type_read_device === "Image" ? (
          <>
            <Button onClick={() => showReadDeviceModal(params.row)}>VER</Button>
            <ImageCell id={params.row.id} getImage={getImage} />
          </>
        ) : (
          <p style={{ textAlign: "center", flex: "auto" }}>---</p>
        ),
    },
    {
      field: "debug",
      headerName: "Debug",
      width: 70,
      sortable: false,
      renderCell: (params: { row: ItemReadDeviceDataRow }) =>
        params.row.type_read_device === "Image" ? (
          <>
            <Button onClick={() => showReadDeviceDebugModal(params.row)}>
              VER
            </Button>
          </>
        ) : (
          <p style={{ textAlign: "center", flex: "auto" }}>---</p>
        ),
    },
    {
      field: "mode",
      headerName: "Modo",
      width: 70,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.mode,
      renderCell: (params: { row: ItemReadDeviceDataRow }) => {
        return <p>{t(params.row.mode)}</p>;
      },
    },
    {
      field: "choices-doctomatic",
      headerName: "Propuesta",
      width: 160,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        JSON.stringify(params.row.internalAiResponse?.proposedMeasurements),
      renderCell: (params: { row: ItemReadDeviceDataRow }) => {
        if (params.row.device.toLowerCase().includes("form")) return "--";

        const title = params.row.internalAiResponse?.proposedMeasurements
          ?.map((p) => `${t(p.sign)}: ${p.value}`)
          .join("\n");
        return (
          <Tooltip
            title={title}
            disableInteractive
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: "14px",
                  maxWidth: 800,
                  whiteSpace: "pre-line",
                },
              },
            }}
          >
            <div
              style={
                params.row.device.toLowerCase().includes("form")
                  ? { whiteSpace: "pre-line", wordWrap: "break-word" }
                  : {}
              }
            >
              {params.row.internalAiResponse?.proposedMeasurements?.map(
                (p, index) => (
                  <span key={index}>
                    {t(p.sign)}: <strong>{p.value}</strong>
                    <br />
                  </span>
                )
              )}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "measurement",
      headerName: "Medición",
      width: 150,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.measurementsInRead,
      renderCell: (params: { row: ItemReadDeviceDataRow }) => {
        if (params.row.device.toLowerCase().includes("form")) return "--";

        function getSingUnit(unit: any) {
          if (SING_UNIT_NOT_SHOW.includes(unit)) return "";
          return unit.includes("percentage") ? "%" : unit.replace("_", "/");
        }

        const title = params.row.measurementsInRead
          ?.map((m) => `${t(m.sign_name)}: ${m.value}${getSingUnit(m.unit)}`)
          .join("\n");
        return (
          <Tooltip
            title={title}
            disableInteractive
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: "14px",
                  maxWidth: 800,
                  whiteSpace: "pre-line",
                },
              },
            }}
          >
            <div
              style={
                params.row.device.toLowerCase().includes("form")
                  ? { whiteSpace: "pre-line", wordWrap: "break-word" }
                  : {}
              }
            >
              {params.row.measurementsInRead?.map((m, index) => (
                <span key={index}>
                  {t(m.sign_name)}:{" "}
                  <strong>
                    {m.value}
                    {getSingUnit(m.unit)}
                  </strong>
                  <br />
                </span>
              ))}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "choices-internal",
      headerName: "Propuesta IA Int.",
      width: 160,
      sortable: false,
      hide: !internalAiResponse,
      valueGetter: (params: GridValueGetterParams) =>
        JSON.stringify(params.row.internalAiResponse?.proposedMeasurements),
      renderCell: (params: { row: ItemReadDeviceDataRow }) => {
        const proposedMeasurements =
          params.row.internalAiResponse?.proposedMeasurements;
        if (showChoicesModal && proposedMeasurements) {
          const pmToString = proposedMeasurements.map((pm) => {
            return JSON.stringify(pm);
          });
          return (
            <Button onClick={() => showChoicesModal(pmToString)}>VER</Button>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      field: "choices-external",
      headerName: "Propuesta IA Ext.",
      width: 160,
      sortable: false,
      hide: !externalAiResponse,
      valueGetter: (params: GridValueGetterParams) =>
        JSON.stringify(params.row.externalAiResponse?.response.choices),
      renderCell: (params: { row: ExtendedItemReadDeviceDataRow }) => {
        const choices = params.row.externalAiResponse?.response.choices;
        if (showChoicesModal && choices && choices.length > 0) {
          return <Button onClick={() => showChoicesModal(choices)}>VER</Button>;
        } else {
          return <></>;
        }
      },
    },
    {
      field: "translate_image",
      headerName: "Imagen de traducción",
      width: 175,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.translate_image,
      renderCell: (params: { row: ItemReadDeviceDataRow }) => {
        let externalAiIcon = <></>;
        if (params.row.externalAiResponse) {
          externalAiIcon = <AutoFixHighIcon style={{ color: "blue" }} />;
        }
        if (params.row.translate_image === true) {
          return (
            <p>
              <SignLanguageIcon style={{ color: "red" }} /> {externalAiIcon}
            </p>
          );
        } else {
          return (
            <p>
              <BoltIcon style={{ color: "green" }} /> {externalAiIcon}
            </p>
          );
        }
      },
    },
    {
      field: "translation_status",
      headerName: "Estado traducción",
      width: 175,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.trtranslation_statusans,
      renderCell: (params: { row: ItemReadDeviceDataRow }) => {
        if (params.row.translation_status) {
          const status = params.row.translation_status;
          if (status === TranslationStatus.PENDING) {
            return (
              <p>
                <AccessTimeIcon style={{ color: "blue" }} />
              </p>
            );
          } else if (status === TranslationStatus.TRANSLATED) {
            return (
              <p>
                <DoneIcon style={{ color: "green" }} />
              </p>
            );
          } else if (status === TranslationStatus.REMOVED) {
            return (
              <p>
                <DeleteIcon style={{ color: "red" }} />
              </p>
            );
          }
        } else {
          return null;
        }
      },
    },
    {
      field: "translated_at",
      headerName: "Fecha traducción",
      width: 150,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.translated_at,
      renderCell: (params: { row: ItemReadDeviceDataRow }) => {
        if (params.row.translated_at) {
          return (
            <p>
              {moment(params.row.translated_at).format("DD-MM-yyyy HH:mm:ss")}
            </p>
          );
        }
        return <p style={{ textAlign: "center", flex: "auto" }}>---</p>;
      },
    },
    {
      field: "translation_time",
      headerName: "Tiempo de traducción",
      width: 150,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.translated_at - params.row.created_at,
      renderCell: (params: { row: ItemReadDeviceDataRow }) => {
        if (params.row.translated_at) {
          const duration = moment.duration(
            moment
              .utc(params.row.translated_at)
              .diff(moment.utc(params.row.created_at))
          );

          const days = duration.days();
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          const formattedDifference = `${days > 0 ? `${days}d ` : ""}${
            hours > 0 ? `${hours}h ` : ""
          }${minutes > 0 ? `${minutes}m ` : ""}${seconds}s`;

          const color =
            duration.asMinutes() < 3
              ? "#8eff63" // Pastel green
              : duration.asMinutes() < 6
              ? "#ffdc63" // Pastel yellow
              : "#fb6363"; // Pastel red

          return (
            <div
              style={{
                backgroundColor: color,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#333",
              }}
            >
              {formattedDifference}
            </div>
          );
        }
        return <p style={{ textAlign: "center", flex: "auto" }}>---</p>;
      },
    },
    // {
    //   field: "sankey_graph",
    //   headerName: "Sankey",
    //   width: 70,
    //   sortable: false,
    //   renderCell: (params: { row: ItemReadDeviceDataRow }) => (
    //     <Button onClick={() => showSankeyGraphModal(params.row)}>
    //       VER
    //     </Button>
    //   ),
    // },
  ];

  if (extendedData) {
    const originalDevice = {
      field: "original-device",
      headerName: "Dispositivo Original",
      width: 200,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.originalDevice,
      renderCell: (params: { row: ItemReadDeviceDataRow }) => {
        return <p>{t(params.row.originalDevice)}</p>;
      },
    };

    gridColumns.splice(1, 0, originalDevice);
    let extendedDataGridColumns: GridColDef[] = [
      {
        field: "company",
        headerName: "Compañía",
        width: 200,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) => params.row.company_name,
        renderCell: (params: { row: ExtendedItemReadDeviceDataRow }) => {
          const href = `/dashboard?company=${params.row.company_name}`;
          return (
            <a href={href} target="_blank">
              {t(params.row.company_name)}
            </a>
          );
        },
      },
      {
        field: "taken_by_id",
        headerName: "Imágen tomada por",
        width: 150,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) => params.row.taken_by_id,
        renderCell: (params: { row: ExtendedItemReadDeviceDataRow }) => {
          return <p>{params.row.taken_by_id}</p>;
        },
      },
      {
        field: "patient_id",
        headerName: "Paciente",
        width: 100,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) => params.row.patient_id,
        renderCell: (params: { row: ExtendedItemReadDeviceDataRow }) => {
          return <p>{params.row.patient_id}</p>;
        },
      },
      {
        field: "translator1_id",
        headerName: "Traductor 1",
        width: 100,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.translator1_id,
        renderCell: (params: { row: ExtendedItemReadDeviceDataRow }) => {
          if (params.row.translator1_id) {
            return <p>{params.row.translator1_id}</p>;
          }
          return <p style={{ textAlign: "center", flex: "auto" }}>---</p>;
        },
      },
      {
        field: "translator2_id",
        headerName: "Traductor 2",
        width: 100,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.translator2_id,
        renderCell: (params: { row: ExtendedItemReadDeviceDataRow }) => {
          if (params.row.translator2_id) {
            return <p>{params.row.translator2_id}</p>;
          }
          return <p style={{ textAlign: "center", flex: "auto" }}>---</p>;
        },
      },
    ];
    gridColumns.push(...extendedDataGridColumns);
  }

  if (externalAiResponse) {
    let externalAiResponseGridColumns: GridColDef[] = [
      {
        field: "source",
        headerName: "Origen",
        width: 125,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.externalAiResponse?.source,
        renderCell: (params: { row: ExtendedItemReadDeviceDataRow }) => {
          return <p>{params.row.externalAiResponse?.source}</p>;
        },
      },
      {
        field: "model-external",
        headerName: "Modelo IA Ext.",
        width: 150,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.externalAiResponse?.response.model,
        renderCell: (params: { row: ExtendedItemReadDeviceDataRow }) => {
          return <p>{params.row.externalAiResponse?.response.model}</p>;
        },
      },
      {
        field: "usage-input",
        headerName: "Tokens input",
        width: 100,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) =>
          JSON.stringify(params.row.externalAiResponse?.response.usage),
        renderCell: (params: { row: ExtendedItemReadDeviceDataRow }) => {
          const inputTokens =
            params.row.externalAiResponse?.response.usage.prompt_tokens;
          if (inputTokens) return <p>{inputTokens} tokens</p>;
          return <p style={{ textAlign: "center", flex: "auto" }}>---</p>;
        },
      },
      {
        field: "usage-output",
        headerName: "Tokens output",
        width: 100,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) =>
          JSON.stringify(params.row.externalAiResponse?.response.usage),
        renderCell: (params: { row: ExtendedItemReadDeviceDataRow }) => {
          const responseTokens =
            params.row.externalAiResponse?.response.usage.completion_tokens;
          if (responseTokens) return <p>{responseTokens} tokens</p>;
          return <p style={{ textAlign: "center", flex: "auto" }}>---</p>;
        },
      },
      {
        field: "usage-cost",
        headerName: "Coste estimado",
        width: 125,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) =>
          JSON.stringify(params.row.externalAiResponse?.response.usage),
        renderCell: (params: { row: ExtendedItemReadDeviceDataRow }) => {
          const inputTokens =
            params.row.externalAiResponse?.response.usage.prompt_tokens;
          const outputTokens =
            params.row.externalAiResponse?.response.usage.completion_tokens;
          if (inputTokens && outputTokens) {
            const inputPrice = inputTokenPrice * inputTokens;
            const outputPrice = outputTokenPrice * outputTokens;
            return <p>{(inputPrice + outputPrice).toFixed(5)} €</p>;
          }
        },
      },
    ];
    gridColumns.push(...externalAiResponseGridColumns);
  }

  if (internalAiResponse) {
    let internalAiResponseGridColumns: GridColDef[] = [
      {
        field: "model-internal",
        headerName: "Modelo IA Int.",
        width: 150,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.internalAiResponse?.model,
        renderCell: (params: { row: ItemReadDeviceDataRow }) => {
          return <p>{params.row.internalAiResponse?.model}</p>;
        },
      },
    ];
    gridColumns.push(...internalAiResponseGridColumns);
  }

  return gridColumns;
};
