import React, { useEffect, useRef, useState } from "react";
import { Box, Modal, Typography } from "@material-ui/core";
import { Chart } from "react-google-charts";
import { LegendData } from "./Legend";

export type SankeyChartData = (
  | string
  | number
  | { type: string; role: string; p: { html: boolean } }
)[][];

export interface IGraph {
  data: SankeyChartData;
  handleChartError: () => void;
}

const options = {
  sankey: {
    node: {
      interactivity: true, // Habilita la interacción con los nodos
      width: 10, // Aumenta el ancho de los nodos para etiquetas largas
      nodePadding: 100, // Mayor espacio entre los nodos
      label: {
        fontName: "Arial", // Fuente de las etiquetas
        fontSize: 10, // Tamaño de fuente para etiquetas más compactas
        color: "#000", // Color de las etiquetas
        bold: false, // Desactiva negrita para hacer las etiquetas más compactas
        italic: false, // No usar itálica
      },
    },
    link: {
      colorMode: "target",
      fillOpacity: 0.5, // Hacer los enlaces ligeramente transparentes
    },
  },
  tooltip: {
    isHtml: true, // Habilita tooltips en HTML
    trigger: "selection",
  },
};

const Graph = ({ data, handleChartError }: IGraph): React.ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleOpenModal = (content: string) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent("");
  };

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "calc(100vh - 20px)",
        overflow: "hidden",
      }}
    >
      <Chart
        chartType="Sankey"
        width="100%"
        height="70%"
        data={data}
        options={options}
        chartEvents={[
          { eventName: "error", callback: handleChartError },
          {
            eventName: "select",
            callback: ({ chartWrapper }) => {
              if (chartWrapper) {
                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();

                if (selection.length > 0) {
                  const event = (selection[0] as any)?.name;
                  const eventData = data.find((x) => x[1] === event);
                  if (eventData) {
                    const tooltip = eventData[3] as string;
                    handleOpenModal(tooltip);
                  }
                }
              }
            },
          },
        ]}
      />
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="tooltip-modal-title"
        aria-describedby="tooltip-modal-description"
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            mt: 10
          }}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div
            id="tooltip-modal-description"
            dangerouslySetInnerHTML={{ __html: modalContent || "" }}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export { Graph };
