import React, { useState, createContext, useContext } from "react";
import Fetcher from "./modules/Fetcher";
import { useProfile } from "./modules/Profile";
import { useUser } from "./modules/User";
import { useDevices } from "./modules/Devices";
import { useDiseases } from "./modules/Diseases";
import { useMeasurements } from "./modules/Measurements";
import { useFrequencies } from "./modules/Frequencies";
import { useCompany } from "./modules/Company";
import { useCompanies } from "./modules/Companies";
import { useMail } from "./modules/Mail";
import { useSMS } from "./modules/SMS";
import { usePayments } from "./modules/Payments";
import { useFiles } from "./modules/Files";
import { useTickets } from "./modules/Tickets";
import { useLegal } from "./modules/Legal";
import { useSigns } from "./modules/Signs";
import { useAddresses } from "./modules/Addresses";
import { useUserDevice } from "./modules/UserDevice";
import { useImages } from "./modules/Images";
import type { IUseProfile } from "./modules/Profile";
import type { IUseUser } from "./modules/User";
import type { IUseDevices } from "./modules/Devices";
import type { IUseDiseases } from "./modules/Diseases";
import type { IUseMeasurements } from "./modules/Measurements";
import type { IUseCompany } from "./modules/Company";
import type { IUseCompanies } from "./modules/Companies";
import type { IUseMail } from "./modules/Mail";
import type { IUseSMS } from "./modules/SMS";
import type { IUseFiles } from "./modules/Files";
import type { IUsePayments } from "./modules/Payments";
import type { IUseFrequencies } from "./modules/Frequencies";
import type { IUseTickets } from "./modules/Tickets";
import type { IUseLegal } from "./modules/Legal";
import type { IUseSigns } from "./modules/Signs";
import type { IUseAddresses } from "./modules/Addresses";
import type { IUseUserDevice } from "./modules/UserDevice";
import type { IUseImages } from "./modules/Images";
import type { LoginResponseDto } from "./dto/Login";
import type { GetTranslationResponseDto } from "./dto/Translations";
import { AddressType } from "./dto/Addresses";
import {
  IUseUserSubscription,
  useUserSubscription,
} from "./modules/UserSubscription";
import {
  IUseTranslateImages,
  useTranslateImages,
} from "./modules/TranslateImages";
import { useStatus, IUseStatus } from "./modules/Status";
import { IUseWebhooks, useWebhooks } from "./modules/Webhooks";
import { Role } from "./dto/User";
import jwt_decode from "jwt-decode";
import { IUsePatients, usePatients } from "./modules/Patients";
import { IUsePatient, usePatient } from "./modules/Patient";
import { IUseNurse, useNurse } from "./modules/Nurse";
import { IUseNurses, useNurses } from "./modules/Nurses";
import { IUseDoctors, useDoctors } from "./modules/Doctors";
import { IUseDoctor, useDoctor } from "./modules/Doctor";
import { IUseAdminCompany, useAdminCompany } from "./modules/AdminCompany";
import {
  IUseAdminCompanies,
  useAdminCompanies,
} from "./modules/AdminCompanies";
import { IUseAlertsByValue, useAlertsByValue } from "./modules/AlertsByValue";
import { IUseLocations, useLocations } from "./modules/Locations";
import {
  IUseGroupPermission,
  useGroupPermissions,
} from "./modules/GroupPermissions";
import {
  IUseAlertConfigsByNoMeasureIndividual,
  useAlertConfigsByNoMeasureIndividual,
} from "./modules/AlertConfigIndividual/AlertConfigsByNoMeasure";
import {
  IUseAlertConfigsByNoMeasureTemplate,
  useAlertConfigsByNoMeasureTemplate,
} from "./modules/AlertConfigTemplate/AlertConfigsByNoMeasureTemplate";
import {
  IUseAlertConfigsByValue,
  useAlertConfigsByValue,
} from "./modules/AlertConfigIndividual/AlertConfigsByValue";
import {
  IUseAlertsByNoMeasure,
  useAlertsByNoMeasure,
} from "./modules/AlertsByNoMeasure";
import { IUseAlerts, useAlerts } from "./modules/Alerts";
import {
  IUseAlertConfigs,
  useAlertConfigs,
} from "./modules/AlertConfigIndividual/AlertConfigs";
import { ResponseApi, TypeOnError } from "./global";
import {
  IUsePatientsByActor,
  usePatientsByActor,
} from "./modules/PatientsByActor";
import {
  IUseLastAlertsByValueByActor,
  useLastAlertsByValueByActor,
} from "./modules/LastAlertsByValueByActor";
import {
  IUseLastAlertsByNoMeasureByActor,
  useLastAlertsByNoMeasureByActor,
} from "./modules/LastAlertsByNoMeasureByActor";
import {
  IUseLastMeasurements,
  useLastMeasurements,
} from "./modules/LastMeasurements";
import {
  IUseActorLocations,
  useActorLocations,
} from "./modules/ActorLocations";
import {
  IUseLastAlertsByNoMeasureByPatient,
  useLastAlertsByNoMeasureByPatient,
} from "./modules/LastAlertsByNoMeasureByPatient";
import {
  IUseLastAlertsByValueByPatient,
  useLastAlertsByValueByPatient,
} from "./modules/LastAlertsByValueByPatient";
import {
  IUsePatientsWithLastMeasurements,
  usePatientsWithLastMeasurements,
} from "./modules/PatientsWithLastMeasurements";
import {
  IUsePatientsByCompany,
  usePatientsByCompany,
} from "./modules/PatientsByCompany";
import {
  IUseConsentDoctors,
  useConsentDoctors,
} from "./modules/ConsentDoctors";
import {
  IUseCompanySubscriptions,
  useCompanySubscriptions,
} from "./modules/CompanySubscriptions";
import {
  IUseDiseasesByCompany,
  useDiseasesByCompany,
} from "./modules/DiseasesByCompany";
import {
  IUsePatientWithTreatments,
  usePatientWithTreatments,
} from "./modules/PatientWithTreatments";
import { IUseTreatment, useTreatment } from "./modules/Treatments/Treatment";
import { IUseTreatments, useTreatments } from "./modules/Treatments/Treatments";
import {
  IUseTreatmentsByActor,
  useTreatmentsByActor,
} from "./modules/Treatments/TreatmentsByActor";
import {
  IUseMeasurementsByTracking,
  useMeasurementsByTracking,
} from "./modules/MeasurementsByTreatment";
import {
  IUseAlertsByValueByPatient,
  useAlertsByValueByPatient,
} from "./modules/AlertsByValueByPatient";
import {
  IUseAlertsByNoMeasureByPatient,
  useAlertsByNoMeasureByPatient,
} from "./modules/AlertsByNoMeasureByPatient";
import {
  IUseAlertConfigsByNoMeasureByPatient,
  useAlertConfigsByNoMeasureByPatient,
} from "./modules/AlertConfigsByNoMeasureByPatient";
import {
  IUseAlertConfigsByValueByPatient,
  useAlertConfigsByValueByPatient,
} from "./modules/AlertConfigsByValueByPatient";
import {
  IUseMeasurementsByAlert,
  useMeasurementsByAlert,
} from "./modules/MeasurementsByAlert";
import {
  IUseAlertByNoMeasure,
  useAlertByNoMeasure,
} from "./modules/AlertByNoMeasure";
import { IUseAlertByValue, useAlertByValue } from "./modules/AlertByValue";
import { IUseActorByRole, useActorByRole } from "./modules/ActorByRole";
import { IUseReadSixMWT, useReadSixMWT } from "./modules/ReadSixMWT/ReadSixMWT";
import { IUseReadForm, useReadForm } from "./modules/ReadForm/ReadForm";
import {
  IUseAlertGroups,
  useAlertGroups,
} from "./modules/AlertGroup/AlertGroup";
import {
  IUseAlertConfigsByValueTemplate,
  useAlertConfigsByValueTemplate,
} from "./modules/AlertConfigTemplate/AlertConfigsByValueTemplate";
import {
  IUseAlertConfigsByValueByPatientFromTemplate,
  useAlertConfigsByValueByPatientFromTemplate,
} from "./modules/AlertConfigsByValueByPatientFromTemplate";
import {
  IUseAlertConfigsByNoMeasureByPatientFromTemplate,
  useAlertConfigsByNoMeasureByPatientFromTemplate,
} from "./modules/AlertConfigsByNoMeasureByPatientFromTemplate";
import { AlertTypeAlert } from "./dto/Alerts/IAlerts";
import {
  IUseAlertsPaginated,
  useAlertsPaginated,
} from "./modules/AlertsPaginated/AlertsPaginated";
import {
  IUseImagesValidatorPaginated,
  SelectOptions,
  useImagesValidatorPaginated,
} from "./modules/ImagesValidatorPaginated/ImagesValidatorPaginated";
import { IUseCompanyActors, useCompanyActors } from "./modules/CompanyActors";
import { IUseCmsDashboard, useCmsDashboard } from "./modules/cmsDashboard";
import { IUseQuestionImage, useQuestionImage } from "./modules/QuestionImage";
import {
  IUseExternalReferences,
  useExternalReferences,
} from "./modules/ExternalReferences/ExternalReferences";
import {
  IUseExternalReferencesByUserId,
  useExternalReferencesByUserId,
} from "./modules/ExternalReferences/ExternalReferencesByUserId";
import {
  IUseCompanyActorsPaginated,
  useCompanyActorsPaginated,
} from "./modules/CompanyActors/CompanyActorsPaginated";
import { IUseUrlRedirect, useUrlRedirect } from "./modules/UrlRedirect";
import {
  IUseAlertConfigsByFrequencyIndividual,
  useAlertConfigsByFrequencyIndividual,
} from "./modules/AlertConfigIndividual/AlertConfigsByFrequency";
import {
  IUseAlertConfigsByFrequencyByPatient,
  useAlertConfigsByFrequencyByPatient,
} from "./modules/AlertConfigsByFrequencyByPatient";
import {
  IUseLastAlertsByFrequencyByActor,
  useLastAlertsByFrequencyByActor,
} from "./modules/LastAlertsByFrequencyByActor";
import {
  IUseAlertsByFrequencyByPatient,
  useAlertsByFrequencyByPatient,
} from "./modules/AlertsByFrequencyByPatient";
import type { IUseForms } from "./modules/Forms";
import { useForms } from "./modules/Forms";
import { Filter } from "./utils/pagination/Filter";
import { IUseReadDevice, useReadDevice } from "./modules/ReadDevice/ReadDevice";
import {
  IUseLastReadDevices,
  useLastReadDevices,
} from "./modules/ReadDevice/LastReadDevice";
import {
  IUseLastAlertsByFrequencyByPatient,
  useLastAlertsByFrequencyByPatient,
} from "./modules/LastAlertsByFrequencyByPatient";
import {
  IUseCmsReadDeviceViewer,
  useCmsReadDeviceViewer,
} from "./modules/CmsReadDeviceViewer";
import { DataRange } from "./utils/query/filter.interface";
import { FilterOption } from "./dto/CmsDashboard/CmsDashboard";
import { IUseSankeyGraph, useSankeyGraph } from "./modules/SankeyGraph";
import { CompanyType } from "./dto/Company";
import { IUseDeviceCompany, useDeviceCompany } from "./modules/DeviceCompany";

export interface ILoginData {
  email: string;
  password: string;
  super: boolean;
  isFromApp?: boolean;
}

export interface IProvideApi extends IProvideLogin, IProvideApiModules { }

export interface IProvideLogin {
  id: number;
  role: Role;
  companyId: number;
  lang: string;
  timezone: string;
  token: string;
  readDeviceDescription: boolean;
  companyType: CompanyType | null;
  login: (userData: ILoginData) => Promise<LoginResponseDto>;
  refreshTokens: (
    refreshToken: string,
    user?: any,
    management?: boolean
  ) => Promise<LoginResponseDto>;
  logout: () => void;
  setToken: (t: string) => void;
}
export interface IProvideApiModules {
  useProfile: (
    getPermissions?: boolean,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseProfile;
  useUser: (
    id: number,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseUser;
  usePatient: (
    id: number,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUsePatient;
  usePatients: (
    showDeleted?: boolean,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUsePatients;
  usePatientsByActor: (
    actorId: number,
    role: "doctors" | "nurses",
    query: any,
    search: string,
    filters: Filter[],
    showDeleted?: boolean,
    removeFinishedTrackings?: boolean,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUsePatientsByActor;
  usePatientsByCompany: (
    companyId: number,
    showDeleted?: boolean,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUsePatientsByCompany;
  useDoctor: (
    id: number,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseDoctor;
  useDoctors: (
    showDeleted?: boolean,
    query?: any,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseDoctors;
  useConsentDoctors: (
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseConsentDoctors;
  useNurse: (
    id: number,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseNurse;
  useNurses: (
    showDeleted?: boolean,
    query?: any,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseNurses;
  useAdminCompany: (
    id: number,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseAdminCompany;
  useAdminCompanies: (
    showDeleted?: boolean,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseAdminCompanies;
  useAlertConfigs: () => IUseAlertConfigs;
  useAlertConfigsByValue: (
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseAlertConfigsByValue;
  useAlertConfigsByNoMeasure: (
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseAlertConfigsByNoMeasureIndividual;
  useAlertConfigsByFrequency: (
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseAlertConfigsByFrequencyIndividual;
  useAlertConfigsByValueTemplate: (
    query?: any,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseAlertConfigsByValueTemplate;
  useAlertConfigsByNoMeasureTemplate: (
    query?: any,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseAlertConfigsByNoMeasureTemplate;
  useAlertConfigsByValueByPatientFromTemplate: (
    patientId: number,
    query?: any,
    customFilter?: string,
    activeFilter?: string,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseAlertConfigsByValueByPatientFromTemplate;
  useAlertConfigsByValueByPatient: (
    patientId: number,
    query: any,
    activeFilter?: string,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseAlertConfigsByValueByPatient;
  useAlertConfigsByNoMeasureByPatient: (
    patientId: number,
    query: any,
    activeFilter?: string,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseAlertConfigsByNoMeasureByPatient;
  useAlertConfigsByNoMeasureByPatientFromTemplate: (
    patientId: number,
    query: any,
    customFilter?: string,
    activeFilter?: string,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseAlertConfigsByNoMeasureByPatientFromTemplate;
  useAlertConfigsByFrequencyByPatient: (
    patientId: number,
    query: any,
    activeFilter?: string,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseAlertConfigsByFrequencyByPatient;
  useUserSubscription: (
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseUserSubscription;
  useTranslateImages: (
    isTranslated?: boolean,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseTranslateImages;
  useDevices: (
    shouldFetch?: boolean,
    onError?: TypeOnError,
    form_device?: boolean,
    companyId?: string,
    showDeletedForms?: boolean
  ) => IUseDevices;
  useDeviceCompany: (
    companyId: number,
    onError?: TypeOnError
  ) => IUseDeviceCompany;
  useForms: (
    showDeleted: boolean,
    query?: any,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseForms;
  useDiseases: (
    query?: any,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseDiseases;
  useDiseasesByCompany: (
    companyId: number,
    query: any,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseDiseasesByCompany;
  useFrequencies: (
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseFrequencies;
  useMeasurements: (
    patientId?: number,
    deviceId?: number,
    date1?: string,
    date2?: string,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseMeasurements;
  useMeasurementsByTracking: (
    trackingId: number,
    date1?: string,
    date2?: string,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseMeasurementsByTracking;
  useMeasurementsByAlert: (
    patientId: number,
    deviceId: number,
    date1?: string,
    date2?: string,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseMeasurementsByAlert;
  useTreatment: (
    id: number,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseTreatment;
  useTreatments: (
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseTreatments;
  useTreatmentsByActor: (
    actorId: number,
    role: Role,
    showEnded: boolean,
    shouldFetch?: boolean,
    query?: any,
    onError?: TypeOnError
  ) => IUseTreatmentsByActor;
  useCompany: (
    companyId: number,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseCompany;
  useCompanies: (shouldFetch?: boolean, onError?: TypeOnError) => IUseCompanies;
  useCompanySubscriptions: (
    companyId: number,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseCompanySubscriptions;
  useFiles: (
    patientId: number,
    query?: any,
    date1?: string,
    date2?: string,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseFiles;
  useMail: (shouldFetch?: boolean, onError?: TypeOnError) => IUseMail;
  useSMS: (shouldFetch?: boolean, onError?: TypeOnError) => IUseSMS;
  usePayments: (shouldFetch?: boolean, onError?: TypeOnError) => IUsePayments;
  useTickets: (shouldFetch?: boolean, onError?: TypeOnError) => IUseTickets;
  useLegal: (shouldFetch?: boolean, onError?: TypeOnError) => IUseLegal;
  useSigns: (shouldFetch?: boolean, onError?: TypeOnError) => IUseSigns;
  useAddresses: (
    addressType: AddressType,
    isDefault: boolean,
    isActive: boolean,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseAddresses;
  useUserDevice: (
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseUserDevice;
  useImages: (shouldFetch?: boolean, onError?: TypeOnError) => IUseImages;
  useQuestionImage: (
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseQuestionImage;
  getTranslations: (
    language: string
  ) => Promise<ResponseApi<GetTranslationResponseDto[]>>;
  useStatus: (
    json: boolean,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseStatus;
  useWebhooks: (shouldFetch?: boolean, onError?: TypeOnError) => IUseWebhooks;
  useAlertsByValue: (
    actorId: number,
    actorRole: Role,
    shouldFetch?: boolean,
    simpleSearch?: string,
    advancedSearch?: any,
    onError?: TypeOnError
  ) => IUseAlertsByValue;
  useAlertsByNoMeasure: (
    actorId: number,
    actorRole: Role,
    shouldFetch?: boolean,
    simpleSearch?: string,
    advancedSearch?: any,
    onError?: TypeOnError
  ) => IUseAlertsByNoMeasure;
  useAlertByValue: (
    id: number,
    actorId: number,
    actorRole: Role,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseAlertByValue;
  useAlertByNoMeasure: (
    id: number,
    actorId: number,
    actorRole: Role,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseAlertByNoMeasure;
  useAlertsByValueByPatient: (
    actorId: number,
    actorRole: Role,
    patientId: number,
    query?: any,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseAlertsByValueByPatient;
  useAlertsByNoMeasureByPatient: (
    actorId: number,
    actorRole: Role,
    patientId: number,
    query?: any,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseAlertsByNoMeasureByPatient;
  useAlertsByFrequencyByPatient: (
    actorId: number,
    actorRole: Role,
    patientId: number,
    query?: any,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseAlertsByFrequencyByPatient;
  useLocations: (shouldFetch?: boolean, onError?: TypeOnError) => IUseLocations;
  useActorLocations: (
    actorId: number,
    role: "doctors" | "nurses" | "patients",
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseActorLocations;
  useActorByRole: (
    actorId: number,
    role: "doctors" | "nurses" | "patients",
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseActorByRole;
  useGroupPermissions: (
    actorId: number,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseGroupPermission;
  useAlerts: (shouldFetch?: boolean, onError?: TypeOnError) => IUseAlerts;
  useLastAlertsByValueByActor: (
    actorId: number,
    actorRole: Role,
    shouldFetch?: boolean,
    query?: any,
    onError?: TypeOnError
  ) => IUseLastAlertsByValueByActor;
  useLastAlertsByNoMeasureByActor: (
    actorId: number,
    actorRole: Role,
    shouldFetch?: boolean,
    query?: any,
    onError?: TypeOnError
  ) => IUseLastAlertsByNoMeasureByActor;
  useLastAlertsByFrequencyByActor: (
    actorId: number,
    actorRole: Role,
    shouldFetch?: boolean,
    query?: any,
    onError?: TypeOnError
  ) => IUseLastAlertsByFrequencyByActor;
  useLastAlertsByNoMeasureByPatient: (
    actorId: number,
    actorRole: Role,
    patientId: number,
    shouldFetch?: boolean,
    query?: any,
    onError?: TypeOnError
  ) => IUseLastAlertsByNoMeasureByPatient;
  useLastAlertsByValueByPatient: (
    actorId: number,
    actorRole: Role,
    patientId: number,
    shouldFetch?: boolean,
    query?: any,
    onError?: TypeOnError
  ) => IUseLastAlertsByValueByPatient;
  useLastAlertsByFrequencyByPatient: (
    actorId: number,
    actorRole: Role,
    patientId: number,
    shouldFetch?: boolean,
    query?: any,
    onError?: TypeOnError
  ) => IUseLastAlertsByFrequencyByPatient;
  useLastMeasurements: (
    actorId: number,
    shouldFetch?: boolean,
    query?: any,
    onError?: TypeOnError
  ) => IUseLastMeasurements;
  usePatientsWithLastMeasurements: (
    actorId: number,
    shouldFetch?: boolean,
    query?: any,
    onError?: TypeOnError
  ) => IUsePatientsWithLastMeasurements;
  usePatientWithTreatments: (
    id: number,
    showEnded: boolean,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUsePatientWithTreatments;
  useReadSixMWT: (
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseReadSixMWT;
  useReadForm: (shouldFetch?: boolean, onError?: TypeOnError) => IUseReadForm;
  useAlertGroups: (query?: any) => IUseAlertGroups;
  useAlertsPaginated: (
    alertTypeAlert: AlertTypeAlert,
    query: any,
    search: string,
    filters: any[],
    patientId?: number,
    shouldFetch?: boolean
  ) => IUseAlertsPaginated;
  useImagesValidatorPaginated: (
    query: any,
    dateRange?: { start: string; end: string },
    readDeviceRange?: { start: number; end: number },
    deviceSelect?: number,
    measurementOnlyPaki?: boolean,
    paki?: SelectOptions,
    validating?: SelectOptions,
    wrongDevice?: SelectOptions,
    removed?: SelectOptions,
    mode?: SelectOptions,
    isStraight?: SelectOptions
  ) => IUseImagesValidatorPaginated;
  useCompanyActors: (
    companyId: number,
    role?: Role,
    name?: string,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseCompanyActors;
  useCmsDashboard: (
    companyId?: number,
    initDate?: string,
    finDate?: string,
    deviceId?: number,
    timeZone?: number,
    shouldFetch?: boolean,
    patientsDeleted?: boolean,
    showExternalReadDevice?: boolean,
    idDeviceInit?: number,
    idDeviceEnd?: number,
    onError?: TypeOnError
  ) => IUseCmsDashboard;
  useReadDevice: (
    readDeviceId?: number,
    patientId?: number,
    query?: any,
    shouldFetch?: boolean,
    patientsDeleted?: boolean,
    onError?: TypeOnError
  ) => IUseReadDevice;
  useLastReadDevices: (
    patientId?: number,
    query?: any,
    deviceId?: number,
    date1?: string,
    date2?: string,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseLastReadDevices;
  useExternalReferences: (
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseExternalReferences;
  useExternalReferencesByUserId: (
    userId: number,
    query?: any,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseExternalReferencesByUserId;
  useCompanyActorsPaginated: (
    companyId: number,
    query: any,
    search: string,
    role?: Role,
    onError?: TypeOnError
  ) => IUseCompanyActorsPaginated;
  useUrlRedirect: (
    url: string,
    shouldFetch?: boolean,
    onError?: TypeOnError
  ) => IUseUrlRedirect;
  useCmsReadDeviceViewer: (
    paginateQuery: any,
    companyId?: number,
    showExternal?: boolean,
    dateRange?: DataRange<string>,
    readDeviceRange?: DataRange<number>,
    device?: number,
    translated?: FilterOption,
    removed?: FilterOption,
    mode?: string,
    pendingTranslation?: FilterOption,
    pendingConfirm?: FilterOption,
    confirmed?: FilterOption,
    extendedData?: boolean,
    externalAiResponse?: boolean,
    onError?: TypeOnError
  ) => IUseCmsReadDeviceViewer;
  useSankeyGraph: (onError?: TypeOnError) => IUseSankeyGraph;
}

function modules(baseURL: string, token: string): IProvideApiModules {
  return {
    useProfile: (
      getPermissions?: boolean,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) => useProfile(baseURL, token, getPermissions, shouldFetch, onError),
    getTranslations: async (language: string) =>
      await Fetcher<Promise<ResponseApi<GetTranslationResponseDto[]>>>(
        baseURL,
        token
      )(`/translations?language=${language}`, "get"),
    useUser: (id: number, shouldFetch?: boolean, onError?: TypeOnError) =>
      useUser(baseURL, token, id, shouldFetch, onError),
    usePatient: (id: number, shouldFetch?: boolean, onError?: TypeOnError) =>
      usePatient(baseURL, token, id, shouldFetch, onError),
    usePatients: (
      showDeleted = false,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) => usePatients(baseURL, token, showDeleted, shouldFetch, onError),
    usePatientsByActor: (
      actorId: number,
      role: "doctors" | "nurses",
      query: any,
      search: string,
      filters: Filter[],
      showDeleted = false,
      removeFinishedTrackings = false,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      usePatientsByActor(
        baseURL,
        token,
        actorId,
        role,
        query,
        search,
        filters,
        showDeleted,
        removeFinishedTrackings,
        shouldFetch,
        onError
      ),
    usePatientsByCompany: (
      companyId: number,
      showDeleted = false,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      usePatientsByCompany(
        baseURL,
        token,
        companyId,
        showDeleted,
        shouldFetch,
        onError
      ),
    useDoctor: (id: number, shouldFetch?: boolean, onError?: TypeOnError) =>
      useDoctor(baseURL, token, id, shouldFetch, onError),
    useDoctors: (
      showDeleted = false,
      query?: any,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) => useDoctors(baseURL, token, showDeleted, query, shouldFetch, onError),
    useConsentDoctors: (shouldFetch?: boolean, onError?: TypeOnError) =>
      useConsentDoctors(baseURL, token, shouldFetch, onError),
    useNurse: (id: number, shouldFetch?: boolean, onError?: TypeOnError) =>
      useNurse(baseURL, token, id, shouldFetch, onError),
    useNurses: (
      showDeleted = false,
      query?: any,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) => useNurses(baseURL, token, showDeleted, query, shouldFetch, onError),
    useAdminCompany: (
      id: number,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) => useAdminCompany(baseURL, token, id, shouldFetch, onError),
    useAdminCompanies: (
      showDeleted?: boolean,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) => useAdminCompanies(baseURL, token, showDeleted, shouldFetch, onError),
    useAlertConfigs: () => useAlertConfigs(baseURL, token),
    useAlertConfigsByNoMeasure: (
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useAlertConfigsByNoMeasureIndividual(
        baseURL,
        token,
        shouldFetch,
        onError
      ),
    useAlertConfigsByFrequency: (
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useAlertConfigsByFrequencyIndividual(
        baseURL,
        token,
        shouldFetch,
        onError
      ),
    useAlertConfigsByValueTemplate: (
      query?: any,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useAlertConfigsByValueTemplate(
        baseURL,
        token,
        query,
        shouldFetch,
        onError
      ),
    useAlertConfigsByNoMeasureTemplate: (
      query?: any,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useAlertConfigsByNoMeasureTemplate(
        baseURL,
        token,
        query,
        shouldFetch,
        onError
      ),
    useAlertConfigsByValue: (shouldFetch?: boolean) =>
      useAlertConfigsByValue(baseURL, token, shouldFetch),
    useAlertConfigsByNoMeasureByPatient: (
      patientId: number,
      query: any,
      activeFilter?: string,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useAlertConfigsByNoMeasureByPatient(
        baseURL,
        token,
        patientId,
        query,
        activeFilter,
        shouldFetch,
        onError
      ),
    useAlertConfigsByNoMeasureByPatientFromTemplate: (
      patientId: number,
      query: any,
      customFilter?: string,
      activeFilter?: string,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useAlertConfigsByNoMeasureByPatientFromTemplate(
        baseURL,
        token,
        patientId,
        query,
        customFilter,
        activeFilter,
        shouldFetch,
        onError
      ),
    useAlertConfigsByValueByPatient: (
      patientId: number,
      query: any,
      activeFilter?: string,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useAlertConfigsByValueByPatient(
        baseURL,
        token,
        patientId,
        query,
        activeFilter,
        shouldFetch,
        onError
      ),
    useAlertConfigsByValueByPatientFromTemplate: (
      patientId: number,
      query?: any,
      customFilter?: string,
      activeFilter?: string,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useAlertConfigsByValueByPatientFromTemplate(
        baseURL,
        token,
        patientId,
        query,
        customFilter,
        activeFilter,
        shouldFetch,
        onError
      ),
    useAlertConfigsByFrequencyByPatient: (
      patientId: number,
      query: any,
      activeFilter?: string,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useAlertConfigsByFrequencyByPatient(
        baseURL,
        token,
        patientId,
        query,
        activeFilter,
        shouldFetch,
        onError
      ),
    useUserSubscription: (shouldFetch?: boolean, onError?: TypeOnError) =>
      useUserSubscription(baseURL, token, shouldFetch, onError),
    useTranslateImages: (
      isTranslated = false,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) => useTranslateImages(baseURL, token, isTranslated, shouldFetch, onError),
    useDevices: (
      shouldFetch?: boolean,
      onError?: TypeOnError,
      form_device?: boolean,
      companyId?: string,
      showDeletedForms?: boolean
    ) =>
      useDevices(
        baseURL,
        token,
        shouldFetch,
        onError,
        form_device,
        companyId,
        showDeletedForms
      ),
    useDeviceCompany: (companyId: number, onError?: TypeOnError) =>
      useDeviceCompany(baseURL, token, companyId, onError),
    useForms: (
      showDeleted: boolean,
      query?: any,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) => useForms(baseURL, token, showDeleted, query, shouldFetch, onError),
    useDiseases: (query?: any, shouldFetch?: boolean, onError?: TypeOnError) =>
      useDiseases(baseURL, token, query, shouldFetch, onError),
    useDiseasesByCompany: (
      companyId: number,
      query: any,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useDiseasesByCompany(
        baseURL,
        token,
        companyId,
        query,
        shouldFetch,
        onError
      ),
    useFrequencies: (shouldFetch?: boolean, onError?: TypeOnError) =>
      useFrequencies(baseURL, token, shouldFetch, onError),
    useMeasurements: (
      patientId?: number,
      deviceId?: number,
      date1?: string,
      date2?: string,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useMeasurements(
        baseURL,
        token,
        patientId,
        deviceId,
        date1,
        date2,
        shouldFetch,
        onError
      ),
    useMeasurementsByTracking: (
      trackingId: number,
      date1?: string,
      date2?: string,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useMeasurementsByTracking(
        baseURL,
        token,
        trackingId,
        date1,
        date2,
        shouldFetch,
        onError
      ),
    useMeasurementsByAlert: (
      patientId: number,
      deviceId: number,
      date1?: string,
      date2?: string,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useMeasurementsByAlert(
        baseURL,
        token,
        patientId,
        deviceId,
        date1,
        date2,
        shouldFetch,
        onError
      ),
    useTreatment: (id: number, shouldFetch?: boolean, onError?: TypeOnError) =>
      useTreatment(baseURL, token, id, shouldFetch, onError),
    useTreatments: (shouldFetch?: boolean, onError?: TypeOnError) =>
      useTreatments(baseURL, token, shouldFetch, onError),
    useTreatmentsByActor: (
      actorId: number,
      role: Role,
      showEnded: boolean,
      shouldFetch?: boolean,
      query?: any,
      onError?: TypeOnError
    ) =>
      useTreatmentsByActor(
        baseURL,
        token,
        actorId,
        role,
        showEnded,
        shouldFetch,
        query,
        onError
      ),
    useCompanies: (shouldFetch?: boolean, onError?: TypeOnError) =>
      useCompanies(baseURL, token, shouldFetch, onError),
    useCompany: (
      companyId: number,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) => useCompany(baseURL, token, companyId, shouldFetch, onError),
    useCompanySubscriptions: (
      companyId: number,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useCompanySubscriptions(baseURL, token, companyId, shouldFetch, onError),
    useMail: (shouldFetch?: boolean, onError?: TypeOnError) =>
      useMail(baseURL, token, shouldFetch, onError),
    useSMS: (shouldFetch?: boolean, onError?: TypeOnError) =>
      useSMS(baseURL, token, shouldFetch, onError),
    usePayments: (shouldFetch?: boolean, onError?: TypeOnError) =>
      usePayments(baseURL, token, shouldFetch, onError),
    useFiles: (
      patientId: number,
      query?: any,
      date1?: string,
      date2?: string,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useFiles(
        baseURL,
        token,
        patientId,
        query,
        date1,
        date2,
        shouldFetch,
        onError
      ),
    useTickets: (shouldFetch?: boolean, onError?: TypeOnError) =>
      useTickets(baseURL, token, shouldFetch, onError),
    useLegal: (shouldFetch?: boolean, onError?: TypeOnError) =>
      useLegal(baseURL, token, shouldFetch, onError),
    useSigns: (shouldFetch?: boolean, onError?: TypeOnError) =>
      useSigns(baseURL, token, shouldFetch, onError),
    useAddresses: (
      addressType: AddressType,
      isDefault: boolean,
      isActive: boolean,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useAddresses(
        baseURL,
        token,
        addressType,
        isDefault,
        isActive,
        shouldFetch,
        onError
      ),
    useUserDevice: (shouldFetch?: boolean, onError?: TypeOnError) =>
      useUserDevice(baseURL, token, shouldFetch, onError),
    useImages: (shouldFetch?: boolean, onError?: TypeOnError) =>
      useImages(baseURL, token, shouldFetch, onError),
    useQuestionImage: (shouldFetch?: boolean, onError?: TypeOnError) =>
      useQuestionImage(baseURL, token, shouldFetch, onError),
    useStatus: (json: boolean, shouldFetch?: boolean, onError?: TypeOnError) =>
      useStatus(baseURL, token, json, shouldFetch, onError),
    useWebhooks: (shouldFetch?: boolean, onError?: TypeOnError) =>
      useWebhooks(baseURL, token, shouldFetch, onError),
    useActorByRole: (
      actorId: number,
      role: "doctors" | "nurses" | "patients",
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) => useActorByRole(baseURL, token, actorId, role, shouldFetch, onError),
    useAlertsByValue: (
      actorId: number,
      actorRole: Role,
      shouldFetch?: boolean,
      simpleSearch?: string,
      advancedSearch?: any,
      onError?: TypeOnError
    ) =>
      useAlertsByValue(
        baseURL,
        token,
        actorId,
        actorRole,
        shouldFetch,
        simpleSearch,
        advancedSearch,
        onError
      ),
    useAlertsByNoMeasure: (
      actorId: number,
      actorRole: Role,
      shouldFetch?: boolean,
      simpleSearch?: string,
      advancedSearch?: any,
      onError?: TypeOnError
    ) =>
      useAlertsByNoMeasure(
        baseURL,
        token,
        actorId,
        actorRole,
        shouldFetch,
        simpleSearch,
        advancedSearch,
        onError
      ),
    useAlertByValue: (
      id: number,
      actorId: number,
      actorRole: Role,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useAlertByValue(
        baseURL,
        token,
        id,
        actorId,
        actorRole,
        shouldFetch,
        onError
      ),
    useAlertByNoMeasure: (
      id: number,
      actorId: number,
      actorRole: Role,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useAlertByNoMeasure(
        baseURL,
        token,
        id,
        actorId,
        actorRole,
        shouldFetch,
        onError
      ),
    useAlertsByValueByPatient: (
      actorId: number,
      actorRole: Role,
      patientId: number,
      query?: any,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useAlertsByValueByPatient(
        baseURL,
        token,
        actorId,
        actorRole,
        patientId,
        query,
        shouldFetch,
        onError
      ),
    useAlertsByNoMeasureByPatient: (
      actorId: number,
      actorRole: Role,
      patientId: number,
      query?: any,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useAlertsByNoMeasureByPatient(
        baseURL,
        token,
        actorId,
        actorRole,
        patientId,
        query,
        shouldFetch,
        onError
      ),
    useAlertsByFrequencyByPatient: (
      actorId: number,
      actorRole: Role,
      patientId: number,
      query?: any,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useAlertsByFrequencyByPatient(
        baseURL,
        token,
        actorId,
        actorRole,
        patientId,
        query,
        shouldFetch,
        onError
      ),
    useLocations: (shouldFetch?: boolean, onError?: TypeOnError) =>
      useLocations(baseURL, token, shouldFetch, onError),
    useActorLocations: (
      actorId: number,
      role: "doctors" | "nurses" | "patients",
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) => useActorLocations(baseURL, token, actorId, role, shouldFetch, onError),
    useGroupPermissions: (
      actorId: number,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) => useGroupPermissions(baseURL, token, actorId, shouldFetch, onError),
    useAlerts: (shouldFetch?: boolean, onError?: TypeOnError) =>
      useAlerts(baseURL, token, shouldFetch, onError),
    useLastAlertsByValueByActor: (
      actorId: number,
      actorRole: Role,
      shouldFetch?: boolean,
      query?: any,
      onError?: TypeOnError
    ) =>
      useLastAlertsByValueByActor(
        baseURL,
        token,
        actorId,
        actorRole,
        shouldFetch,
        query,
        onError
      ),
    useLastAlertsByNoMeasureByActor: (
      actorId: number,
      actorRole: Role,
      shouldFetch?: boolean,
      query?: any,
      onError?: TypeOnError
    ) =>
      useLastAlertsByNoMeasureByActor(
        baseURL,
        token,
        actorId,
        actorRole,
        shouldFetch,
        query,
        onError
      ),
    useLastAlertsByFrequencyByActor: (
      actorId: number,
      actorRole: Role,
      shouldFetch?: boolean,
      query?: any,
      onError?: TypeOnError
    ) =>
      useLastAlertsByFrequencyByActor(
        baseURL,
        token,
        actorId,
        actorRole,
        shouldFetch,
        query,
        onError
      ),
    useLastAlertsByNoMeasureByPatient: (
      actorId: number,
      actorRole: Role,
      patientId: number,
      shouldFetch?: boolean,
      query?: any,
      onError?: TypeOnError
    ) =>
      useLastAlertsByNoMeasureByPatient(
        baseURL,
        token,
        actorId,
        actorRole,
        patientId,
        shouldFetch,
        query,
        onError
      ),
    useLastAlertsByValueByPatient: (
      actorId: number,
      actorRole: Role,
      patientId: number,
      shouldFetch?: boolean,
      query?: any,
      onError?: TypeOnError
    ) =>
      useLastAlertsByValueByPatient(
        baseURL,
        token,
        actorId,
        actorRole,
        patientId,
        shouldFetch,
        query,
        onError
      ),
    useLastAlertsByFrequencyByPatient: (
      actorId: number,
      actorRole: Role,
      patientId: number,
      shouldFetch?: boolean,
      query?: any,
      onError?: TypeOnError
    ) =>
      useLastAlertsByFrequencyByPatient(
        baseURL,
        token,
        actorId,
        actorRole,
        patientId,
        shouldFetch,
        query,
        onError
      ),
    useLastMeasurements: (
      actorId: number,
      shouldFetch?: boolean,
      query?: any,
      onError?: TypeOnError
    ) =>
      useLastMeasurements(baseURL, token, actorId, shouldFetch, query, onError),
    usePatientsWithLastMeasurements: (
      actorId: number,
      shouldFetch?: boolean,
      query?: any,
      onError?: TypeOnError
    ) =>
      usePatientsWithLastMeasurements(
        baseURL,
        token,
        actorId,
        shouldFetch,
        query,
        onError
      ),
    usePatientWithTreatments: (
      id: number,
      showEnded: boolean,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      usePatientWithTreatments(
        baseURL,
        token,
        id,
        showEnded,
        shouldFetch,
        onError
      ),
    useReadSixMWT: (shouldFetch?: boolean, onError?: TypeOnError) =>
      useReadSixMWT(baseURL, token, shouldFetch, onError),
    useReadForm: (shouldFetch?: boolean, onError?: TypeOnError) =>
      useReadForm(baseURL, token, shouldFetch, onError),
    useAlertGroups: (query?: any) => useAlertGroups(baseURL, token, query),
    useAlertsPaginated: (
      alertTypeAlert: AlertTypeAlert,
      query: any,
      search: string,
      filters: any[],
      patientId?: number,
      shouldFetch: boolean = true
    ) =>
      useAlertsPaginated(
        baseURL,
        token,
        alertTypeAlert,
        query,
        search,
        filters,
        patientId,
        shouldFetch
      ),
    useImagesValidatorPaginated: (
      query: any,
      dateRange?: { start: string; end: string },
      readDeviceRange?: { start: number; end: number },
      deviceSelect?: number,
      measurementOnlyPaki?: boolean,
      paki?: SelectOptions,
      validating?: SelectOptions,
      wrongDevice?: SelectOptions,
      removed?: SelectOptions,
      mode?: SelectOptions,
      isStraight?: SelectOptions
    ) =>
      useImagesValidatorPaginated(
        baseURL,
        token,
        query,
        dateRange,
        readDeviceRange,
        deviceSelect,
        measurementOnlyPaki,
        paki,
        validating,
        wrongDevice,
        removed,
        mode,
        isStraight
      ),
    useCompanyActors: (
      companyId: number,
      role?: Role,
      name?: string,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useCompanyActors(
        baseURL,
        token,
        companyId,
        role,
        name,
        shouldFetch,
        onError
      ),
    useCmsDashboard: (
      companyId?: number,
      initDate?: string,
      finDate?: string,
      deviceId?: number,
      timeZone?: number,
      shouldFetch?: boolean,
      patientsDeleted?: boolean,
      showExternalReadDevice?: boolean,
      idDeviceInit?: number,
      idDeviceEnd?: number,
      onError?: TypeOnError
    ) =>
      useCmsDashboard(
        baseURL,
        token,
        companyId,
        initDate,
        finDate,
        deviceId,
        timeZone,
        shouldFetch,
        patientsDeleted,
        showExternalReadDevice,
        idDeviceInit,
        idDeviceEnd,
        onError
      ),
    useExternalReferences: (shouldFetch?: boolean, onError?: TypeOnError) =>
      useExternalReferences(baseURL, token, shouldFetch, onError),
    useExternalReferencesByUserId: (
      userId: number,
      query?: any,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useExternalReferencesByUserId(
        baseURL,
        token,
        userId,
        query,
        shouldFetch,
        onError
      ),
    useCompanyActorsPaginated: (
      companyId: number,
      query: any,
      search: string,
      role?: Role,
      onError?: TypeOnError
    ) =>
      useCompanyActorsPaginated(
        baseURL,
        token,
        companyId,
        query,
        search,
        role,
        onError
      ),
    useUrlRedirect: (
      url: string,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) => useUrlRedirect(baseURL, token, url, shouldFetch, onError),
    useReadDevice: (
      readDeviceId?: number,
      patientId?: number,
      query?: any,
      shouldFetch?: boolean,
      //TODO mirar esto. si quitas patientsDeleted da error
      patientsDeleted?: boolean,
      onError?: TypeOnError
    ) =>
      useReadDevice(
        baseURL,
        token,
        readDeviceId,
        patientId,
        query,
        shouldFetch,
        onError
      ),
    useLastReadDevices: (
      patientId?: number,
      query?: any,
      deviceId?: number,
      date1?: string,
      date2?: string,
      shouldFetch?: boolean,
      onError?: TypeOnError
    ) =>
      useLastReadDevices(
        baseURL,
        token,
        patientId,
        query,
        deviceId,
        date1,
        date2,
        shouldFetch,
        onError
      ),
    useCmsReadDeviceViewer: (
      paginateQuery: any,
      companyId?: number,
      showExternal?: boolean,
      dateRange?: DataRange<string>,
      readDeviceRange?: DataRange<number>,
      device?: number,
      translated?: FilterOption,
      removed?: FilterOption,
      mode?: string,
      pendingTranslation?: FilterOption,
      pendingConfirm?: FilterOption,
      confirmed?: FilterOption,
      extendedData?: boolean,
      externalAiResponse?: boolean,
      onError?: TypeOnError
    ) =>
      useCmsReadDeviceViewer(
        baseURL,
        token,
        paginateQuery,
        companyId,
        showExternal,
        dateRange,
        readDeviceRange,
        device,
        translated,
        removed,
        mode,
        pendingTranslation,
        pendingConfirm,
        confirmed,
        extendedData,
        externalAiResponse,
        onError
      ),
    useSankeyGraph: (onError?: TypeOnError) =>
      useSankeyGraph(baseURL, token, onError),
  };
}

export function useProvideApi(baseURL: string): IProvideApi {
  const [token, setToken] = useState("");

  let role: any;
  try {
    const JWT_REGEX: RegExp =
      /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/;
    const isJwt: boolean = JWT_REGEX.test(token);
    const decoded: any = isJwt ? jwt_decode(token) : undefined;
    role = decoded && typeof decoded !== "string" && decoded?.role;
    const id = decoded && typeof decoded !== "string" && decoded?.sub;
    const companyId =
      decoded && typeof decoded !== "string" && decoded?.companyId;
    const lang = decoded && typeof decoded !== "string" && decoded?.lang;
    const timezone =
      decoded && typeof decoded !== "string" && decoded?.timezone;
    const readDeviceDescription =
      decoded && typeof decoded !== "string" && decoded?.readDeviceDescription;
    const companyType =
      decoded && typeof decoded !== "string" && decoded?.companyType;

    return {
      id,
      role,
      companyId,
      lang,
      timezone,
      token,
      companyType,
      setToken,
      readDeviceDescription,
      login: async (userData: ILoginData) => {
        const { accessToken, refreshToken } = await Fetcher<
          LoginResponseDto,
          ILoginData
        >(baseURL, token)("/token", "post", userData);
        setToken(accessToken);
        return { accessToken, refreshToken };
      },
      refreshTokens: async (
        refreshToken: string,
        user?: any,
        management?: boolean
      ) => {
        const { data } = await Fetcher<ResponseApi<LoginResponseDto>>(
          baseURL,
          refreshToken
        )("/token/refresh", "post", { user: user, management: management });
        if (!data) throw new Error("Refresh tokens failed");
        const { accessToken, refreshToken: newRefreshToken } = data;
        setToken(accessToken);
        return { accessToken, refreshToken: newRefreshToken };
      },
      logout: () => {
        setToken("");
      },
      ...modules(baseURL, token),
    };
  } catch (error: any) {
    throw new Error(error.message);
  }
}

// CreateContext asks for a default value
const apiContext = createContext<IProvideApi>({
  id: 1,
  role: Role.user,
  companyId: 0,
  lang: "en",
  timezone: "Europe/Madrid",
  token: "",
  readDeviceDescription: false,
  companyType: null,
  setToken: () => { },
  login: async () => ({ accessToken: "", refreshToken: "" }),
  refreshTokens: async () => ({ accessToken: "", refreshToken: "" }),
  logout: () => { },
  ...modules("", ""),
});

interface ProvideApiProps {
  baseURL: string;
  children: React.ReactNode;
}

export function ProvideApi({
  baseURL,
  children,
}: ProvideApiProps): React.ReactElement {
  const api = useProvideApi(baseURL);
  return <apiContext.Provider value={api}>{children}</apiContext.Provider>;
}

export const useApi = (): IProvideApi => {
  return useContext(apiContext);
};
