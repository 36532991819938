import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export interface ILegendModal {
  open: boolean;
  onClose: () => void;
  data: LegendData[];
}

export interface LegendData {
  event: string;
  description: string;
}
const LegendModal = ({
  open,
  onClose,
  data,
}: ILegendModal): React.ReactElement => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg" // Asegura que el modal tenga suficiente ancho
    >
      <DialogTitle>Leyenda gráfico Sankey</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "30%" }}>
                  {" "}
                  {/* Define un ancho fijo */}
                  <strong>Evento</strong>
                </TableCell>
                <TableCell style={{ width: "70%" }}>
                  {" "}
                  {/* Más ancho para la descripción */}
                  <strong>Descripción</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell style={{ wordBreak: "break-word" }}>
                    {item.event}
                  </TableCell>
                  <TableCell style={{ wordBreak: "break-word" }}>
                    {item.description}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { LegendModal };
