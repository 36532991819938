import { Page } from "../../Page";
import { GetSankeyGraphResponseData } from "@doctomatic/sdk/build/dto/CmsDashboard/CmsDashboard";
import { useEffect, useState } from "react";
import { CustomEvent } from "@doctomatic/dto/dist/eventManager/events.enum";
import { Graph, SankeyChartData } from "./Graph";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { LegendData, LegendModal } from "./Legend";
import { toast } from "react-toastify";
import { useLoading } from "../../../Loading/Loading";

export interface ISankeyGraph {
  traces: GetSankeyGraphResponseData[];
}

// ? Custom tooltip
const createTooltip = (
  source: string,
  target: string,
  value: number,
  percentageReadDevice: number,
  percentagePreviousValue: number,
  legendData: LegendData[]
): string => {
  const sourceDescription = legendData.find(
    (x) => x.event === source.replace(/\n/g, "_")
  );
  const targetDescription = legendData.find(
    (x) => x.event === target.replace(/\n/g, "_")
  );

  // HTML Tooltip
  return `
    <div style="padding: 10px; background-color: white; color: black; border: 1px solid #ccc; border-radius: 4px; width: 250px; box-sizing: border-box; transition: transform 0.2s;">
      <strong>Source event:</strong> ${source.replace(/\n/g, " ")}<br />
      <strong>Target event:</strong> ${target.replace(/\n/g, " ")}<br />
      <strong>Value:</strong> ${value}<br />
      <strong>Value %/new read device:</strong> ${percentageReadDevice.toFixed(
        2
      )}%<br />
      <strong>Value %/previous step:</strong> ${percentagePreviousValue.toFixed(
        2
      )}%<br />
      <a href="#"
        onclick="this.nextElementSibling.style.display = this.nextElementSibling.style.display === 'block' ? 'none' : 'block'; return false;"
        style="color: blue; text-decoration: underline; cursor: pointer;">
        Ver más
      </a>
      <div style="margin-top: 10px; border-top: 1px solid #ccc; padding-top: 10px; display: none;">
        <strong>Source description:</strong> ${
          sourceDescription ? sourceDescription.description : "N/A"
        }<br />
        <strong>Target description:</strong> ${
          targetDescription ? targetDescription.description : "N/A"
        }<br />
      </div>
    </div>
  `;
};

const splitLabel = (label: string) => {
  return label.replace(/_/g, "\n"); // Reemplaza "_" por saltos de línea
};

const formatTracesToSankeyData = (
  traces: GetSankeyGraphResponseData[],
  legendData: LegendData[]
): SankeyChartData => {
  if (traces.length === 0) return [];

  const initialStep = traces.filter(
    (t) => t.source === CustomEvent.NEW_READ_DEVICE
  );
  const initialStepValue = initialStep.reduce(
    (total, t) => (total += t.value),
    0
  );

  const chartData: SankeyChartData = [
    [
      "From",
      "To",
      "Weight",
      { type: "string", role: "tooltip", p: { html: true } },
    ],
  ];

  traces.forEach((item) => {
    let percentageReadDevice = 100;
    let percentagePreviousValue = 100;

    const source = splitLabel(item.source);
    const target = splitLabel(item.target);

    if (source !== CustomEvent.NEW_READ_DEVICE) {
      if (initialStepValue !== 0) {
        percentageReadDevice = (item.value / initialStepValue) * 100;
      }

      const previousStepTraces = traces.filter((t) => t.target === item.source);
      const previousStepValue = previousStepTraces.reduce(
        (total, t) => (total += t.value),
        0
      );

      if (previousStepValue !== 0) {
        percentagePreviousValue = (item.value / previousStepValue) * 100;
      }
    }

    const tooltip = createTooltip(
      item.source,
      item.target,
      item.value,
      percentageReadDevice,
      percentagePreviousValue,
      legendData
    );

    chartData.push([source, target, item.value, tooltip]);
  });

  return chartData;
};

const SankeyGraph = ({ traces }: ISankeyGraph): React.ReactElement => {
  const [data, setData] = useState<SankeyChartData>([]);
  const [errorTraces, setErrorTraces] = useState<SankeyChartData>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [legendOpen, setLegendOpen] = useState<boolean>(false);
  const [legendData, setLegendData] = useState<LegendData[]>([]);
  const { setIsLoading } = useLoading();

  useEffect(() => {
    setData(formatTracesToSankeyData(traces, legendData));
  }, [traces]);

  useEffect(() => {
    const fetchEventsLegend = async () => {
      setIsLoading(true);
      try {
        const eventsLegend: LegendData[] = require("./sankeyLegend.json");
        setLegendData(eventsLegend);
      } catch (err: any) {
        toast.error("Error al cargar la leyenda del gráfico Sankey", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEventsLegend();
  }, []);

  const handleChartError = () => {
    const spans = Array.from(document.querySelectorAll("span"));

    const cyclicError = spans.find((span) =>
      span.innerText.includes("Cycle found in rows: ")
    );

    if (cyclicError) {
      const errorMessage = cyclicError.innerText;
      const match = errorMessage.match(/Cycle found in rows: (.*)/);
      if (match && match[1]) {
        const rows = match[1].split(",").map((row) => parseInt(row.trim(), 10));
        filterDataWithErrorRows(rows);
      }
    }
  };

  const filterDataWithErrorRows = (errorRows: number[]) => {
    // Filtrar trazas válidas y cíclicas
    const validTraces = traces.filter((_, index) => !errorRows.includes(index));
    const cyclicTraces = traces.filter((_, index) => errorRows.includes(index));

    // Actualizar datos con las trazas válidas y formatearlas
    setData([]);
    setTimeout(() => {
      setData(formatTracesToSankeyData(validTraces, legendData));
      const formattedCyclicTraces = formatTracesToSankeyData(
        cyclicTraces,
        legendData
      );
      setErrorTraces(formattedCyclicTraces.slice(1));
    }, 0);
  };

  const errorModal = (
    <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
      <DialogTitle>Trazas Erróneas (Cíclicas)</DialogTitle>
      <DialogContent>
        {/* Tabla que muestra las trazas erróneas */}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Source</strong>
                </TableCell>
                <TableCell>
                  <strong>Target</strong>
                </TableCell>
                <TableCell>
                  <strong>Weight</strong>
                </TableCell>
                {/* <TableCell><strong>Tooltip</strong></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {errorTraces.map((trace, index) => {
                const [source, target, value, tooltip] = trace as [
                  string,
                  string,
                  number,
                  string
                ];
                return (
                  <TableRow key={index}>
                    <TableCell>{source}</TableCell>
                    <TableCell>{target}</TableCell>
                    <TableCell>{value}</TableCell>
                    {/* <TableCell>{tooltip}</TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalOpen(false)} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Page title={"Gráfico sankey"} primaryColor="black">
      <>
        <IconButton onClick={() => setLegendOpen(true)}>
          <InfoIcon />
        </IconButton>
        {legendOpen && (
          <LegendModal
            data={legendData}
            open={legendOpen}
            onClose={() => setLegendOpen(false)}
          />
        )}
        {/* Display error traces */}
        {errorTraces.length > 0 && (
          <>
            <Button
              size="small"
              variant="contained"
              onClick={() => setModalOpen(true)}
            >
              Ver trazas erróneas
            </Button>
            {errorModal}
          </>
        )}

        <Graph data={data} handleChartError={handleChartError} />
      </>
    </Page>
  );
};

export { SankeyGraph };
