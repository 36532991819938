import React, { useEffect, useState } from "react";
import { Page } from "../Page";
import { useApi } from "@doctomatic/sdk/build/Api";
import { UpdateSignRequestDto } from "@doctomatic/sdk/build/dto/Sign/Signs";
import { DeviceList } from "./DeviceList";
import { toast } from "react-toastify";
import { DeviceEdit } from "./DeviceEdit";
import { GetDeviceResponseDto } from "@doctomatic/sdk/build/dto/Devices";
import { useLocation, useNavigate } from "react-router-dom";
import { processError } from "../../../App/errorToast";
import { CompaniesSelect } from "../Legal/Legal";
import { Box } from "@mui/material";

interface DevicesProps {
  selectedCompany?: number;
}
interface LocationProps {
  state: unknown;
  companyToSelect: number;
}

const Devices = ({ selectedCompany = 0 }: DevicesProps): React.ReactElement => {
  const history = useNavigate();
  const location = useLocation();
  const state = location.state as LocationProps;
  const companyToSelect = state && state.companyToSelect;
  const [companyId, setCompanyId] = useState<string>(
    companyToSelect ? companyToSelect.toString() : selectedCompany.toString()
  );
  const { useSigns, useDevices, logout } = useApi();
  const { updateAll } = useSigns(true, processError(logout, history));
  const { response: devicesResponse, mutate } = useDevices(
    true,
    processError(logout, history),
    false,
    companyId
  );
  const devices = devicesResponse?.data;
  const [deviceToEdit, setDeviceToEdit] = useState<GetDeviceResponseDto | null>(
    null
  );

  useEffect(() => {
    setCompanyId(companyId);
  }, [companyId]);

  const deviceEditForm = deviceToEdit && (
    <DeviceEdit
      open={true}
      deviceToEdit={deviceToEdit}
      onClose={() => setDeviceToEdit(null)}
      onSave={async (signsToUpdate: UpdateSignRequestDto[]) => {
        try {
          await updateAll(signsToUpdate);
          toast.success("Dispositivo actualizado correctamente");
        } catch (err: any) {
          toast.error("Error al editar el dispositivo");
        }
        setDeviceToEdit(null);
        await mutate();
      }}
    />
  );

  const filters = (
    <Box display="flex" justifyContent="flex-end" height={"100%"}>
      <CompaniesSelect companyId={companyId} setCompanyId={setCompanyId} />
    </Box>
  );

  if (!devices) return <></>;

  return (
    <Page title={"Dispositivos"} buttons={filters}>
      {companyId && (
        <DeviceList
          devices={devices}
          companyId={companyId}
          handleMutate={mutate}
        />
      )}
      {deviceEditForm}
    </Page>
  );
};

export { Devices };
