import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useApi } from "@doctomatic/sdk/build/Api";
import {
  ExtendedGetDeviceResponseDto,
  UpdateDeviceCompanyRequestDto,
} from "@doctomatic/sdk/build/dto/Devices";
import Switch from "@material-ui/core/Switch";
import { processError } from "../../../App/errorToast";
import { useNavigate } from "react-router-dom";

const formTypeName = "Form";
const spanishLanguageCode = "es";
interface Props {
  devices: ExtendedGetDeviceResponseDto[];
  companyId: string;
  handleMutate: () => void;
}

const DeviceList = ({
  devices,
  companyId,
  handleMutate,
}: Props): React.ReactElement => {
  const { useDeviceCompany, logout } = useApi();
  const history = useNavigate();
  const { put, remove } = useDeviceCompany(
    +companyId,
    processError(logout, history)
  );

  if (!devices) return <></>;

  const getDeviceName = (device: ExtendedGetDeviceResponseDto) => {
    let name = device.name;
    if (device.typeName === formTypeName && device.formDevice?.json) {
      try {
        const formParsed = JSON.parse(device.formDevice.json);
        if (formParsed) {
          let languageNotFound = true;
          formParsed?.formTitle?.map((x: { lang: string; text: string }) => {
            if (x.lang === spanishLanguageCode) {
              name = x.text;
              languageNotFound = false;
            }
          });
          if (languageNotFound && formParsed?.formTitle?.length > 0) {
            name = formParsed.formTitle[0].text;
          }
        }
      } catch (err) {}
    }
    return name;
  };
  const handleTranslate = async (id: number, value: boolean) => {
    const dto = new UpdateDeviceCompanyRequestDto();
    dto.forceTranslateImage = value;
    await put(id, dto);
    handleMutate();
  };

  const handleActive = async (id: number, value: boolean) => {
    if (value) {
      const dto = new UpdateDeviceCompanyRequestDto();
      await put(id, dto);
    } else {
      await remove(id);
    }

    handleMutate();
  };

  const handleAutoRead = async (id: number, value: boolean) => {
    const dto = new UpdateDeviceCompanyRequestDto();
    dto.autoRead = value;
    await put(id, dto);
    handleMutate();
  };

  const handleVisible = async (id: number, value: boolean) => {
    const dto = new UpdateDeviceCompanyRequestDto();
    dto.visible = value;
    await put(id, dto);
    handleMutate();
  };

  const handleExternalRead = async (id: number, value: boolean) => {
    const dto = new UpdateDeviceCompanyRequestDto();
    dto.externalRead = value;
    await put(id, dto);
    handleMutate();
  };

  return (
    <TableContainer>
      <Table id="devices">
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Activo</TableCell>
            <TableCell>Forzar Traducción de Imágenes</TableCell>
            <TableCell>Detección automática</TableCell>
            <TableCell>Visible</TableCell>
            <TableCell>External Read</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {devices.map((device: ExtendedGetDeviceResponseDto) => (
            <TableRow key={device.id}>
              <TableCell>{getDeviceName(device)}</TableCell>
              <TableCell>
                <Switch
                  id={`${device.id}-active`}
                  checked={device.active}
                  onChange={(event) =>
                    handleActive(device.id, event.target.checked)
                  }
                  inputProps={{ "aria-label": "controlled" }}
                />
              </TableCell>
              <TableCell>
                <Switch
                  id={`${device.id}-forcetranslate`}
                  checked={device.forceTranslateImage}
                  onChange={(event) =>
                    handleTranslate(device.id, event.target.checked)
                  }
                  inputProps={{ "aria-label": "controlled" }}
                  disabled={!device.active}
                />
              </TableCell>
              <TableCell>
                <Switch
                  id={`${device.id}-autoread`}
                  checked={device.autoRead}
                  onChange={(event) =>
                    handleAutoRead(device.id, event.target.checked)
                  }
                  inputProps={{ "aria-label": "controlled" }}
                  disabled={!device.active}
                />
              </TableCell>
              <TableCell>
                <Switch
                  id={`${device.id}-visible`}
                  checked={device.visible}
                  onChange={(event) =>
                    handleVisible(device.id, event.target.checked)
                  }
                  inputProps={{ "aria-label": "controlled" }}
                  disabled={!device.active}
                />
              </TableCell>
              <TableCell>
                <Switch
                  id={`${device.id}-externalRead`}
                  checked={device.externalRead}
                  onChange={(event) =>
                    handleExternalRead(device.id, event.target.checked)
                  }
                  inputProps={{ "aria-label": "controlled" }}
                  disabled={!device.active}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { DeviceList };
