export enum CustomEvent {
  IMAGEMATIC_RECEPTION = "IMAGEMATIC_RECEPTION",
  NEW_READ_DEVICE = "NEW_READ_DEVICE",

  AIMATIC_7S_ERROR = "AIMATIC_7S_ERROR",
  AIMATIC_7S_NO_VALUES_DETECTED = "AIMATIC_7S_NO_VALUES_DETECTED",
  AIMATIC_7S_INCOMPLETE_VALUES_RETURNED = "AIMATIC_7S_INCOMPLETE_VALUES_RETURNED",
  AIMATIC_7S_INCORRECT_VALUES_RETURNED = "AIMATIC_7S_INCORRECT_VALUES_RETURNED",
  AIMATIC_7S_ALL_VALUES_CORRECT = "AIMATIC_7S_ALL_VALUES_CORRECT",
  AIMATIC_N7S_ERROR = "AIMATIC_N7S_ERROR",
  AIMATIC_N7S_NO_VALUES_DETECTED = "AIMATIC_N7S_NO_VALUES_DETECTED",
  AIMATIC_N7S_INCOMPLETE_VALUES_RETURNED = "AIMATIC_N7S_INCOMPLETE_VALUES_RETURNED",
  AIMATIC_N7S_INCORRECT_VALUES_RETURNED = "AIMATIC_N7S_INCORRECT_VALUES_RETURNED",
  AIMATIC_N7S_ALL_VALUES_CORRECT = "AIMATIC_N7S_ALL_VALUES_CORRECT",

  COGNIMATIC_7S_ERROR = "COGNIMATIC_7S_ERROR",
  COGNIMATIC_7S_NO_VALUES_RETURNED = "COGNIMATIC_7S_NO_VALUES_RETURNED",
  COGNIMATIC_7S_NO_VALUES_DUE_TO_INCOHERENCE = "COGNIMATIC_7S_NO_VALUES_DUE_TO_INCOHERENCE",
  COGNIMATIC_7S_INCORRECT_AND_CORRECT_VALUES_RETURNED = "COGNIMATIC_7S_INCORRECT_AND_CORRECT_VALUES_RETURNED",
  COGNIMATIC_7S_ALL_VALUES_CORRECT = "COGNIMATIC_7S_ALL_VALUES_CORRECT",
  COGNIMATIC_7S_INCOMPLETE_SIGNALS_RETURNED = "COGNIMATIC_7S_INCOMPLETE_SIGNALS_RETURNED",

  COGNIMATIC_N7S_ERROR = "COGNIMATIC_N7S_ERROR",
  COGNIMATIC_N7S_NO_VALUES_RETURNED = "COGNIMATIC_N7S_NO_VALUES_RETURNED",
  COGNIMATIC_N7S_NO_VALUES_DUE_TO_INCOHERENCE = "COGNIMATIC_N7S_NO_VALUES_DUE_TO_INCOHERENCE",
  COGNIMATIC_N7S_INCORRECT_AND_CORRECT_VALUES_RETURNED = "COGNIMATIC_N7S_INCORRECT_AND_CORRECT_VALUES_RETURNED",
  COGNIMATIC_N7S_ALL_VALUES_CORRECT = "COGNIMATIC_N7S_ALL_VALUES_CORRECT",
  COGNIMATIC_N7S_INCOMPLETE_SIGNALS_RETURNED = "COGNIMATIC_N7S_INCOMPLETE_SIGNALS_RETURNED",

  EXTERNAL_AI_ERROR = "EXTERNAL_AI_ERROR",
  EXTERNAL_AI_NO_VALUES = "EXTERNAL_AI_NO_VALUES",
  EXTERNAL_AI_INCORRECT_VALUES = "EXTERNAL_AI_INCORRECT_VALUES",
  EXTERNAL_AI_CORRECT_VALUES = "EXTERNAL_AI_CORRECT_VALUES",
  USER_REFUSE = "USER_REFUSE",
  USER_CONFIRM = "USER_CONFIRM",
  PAKI_CREATED = "PAKI_CREATED",
  PAKI_TRANSLATED = "PAKI_TRANSLATED",
  PAKI_REMOVED = "PAKI_REMOVED",
}
