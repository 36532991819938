import { Expose } from "class-transformer";
import { IsNotEmpty } from "class-validator";
import { IGetDeviceResponseDto, GetDeviceResponseDto } from "../Devices";
import { IGetSignResponseDto } from "../Sign/ISign";
import { GetSignResponseDto } from "../Sign/Signs";
import { IGetTreatmentResponseDto } from "../Treatment/ITreatments";
import { 
  IGetReadDeviceDto, 
  IGetReadDeviceExtendedDto, 
  IGetReadDeviceResponseDto, 
  IItemReadDeviceResponseDto, 
  IBasicReadDevice 
} from "./IReadDevice";
import { GetUserResponseDto, IGetUserResponseDto } from "../User";
import { IGetPatientResponseDto } from "../Patient/IPatient";
import { GetPatientResponseDto } from "../Patient/Patient";
import { IGetMeasurementResponseDto } from "../Measurements/IMeasurements";
import { GetMeasurementResponseDto } from "../Measurements/Measurements";

export class BasicReadDevice implements IBasicReadDevice {
  @Expose() id: number = 0;
  @Expose({ name: "created_at" }) createdAt: Date = new Date();
}

export class GetReadDeviceDto implements IGetReadDeviceDto {
  @Expose() @IsNotEmpty() id: number = 0;
  @Expose({ name: "device_id" }) @IsNotEmpty() fromDeviceId: number = 0;
  @Expose({ name: "patient_id" }) @IsNotEmpty() userId: number = 0;
  @Expose() @IsNotEmpty() createdAt: Date = new Date();
  @Expose() checkedAt?: Date;
  @Expose() type: string = "";
}

export class GetReadDeviceExtendedDto
  extends GetReadDeviceDto
  implements IGetReadDeviceExtendedDto
{
  @Expose() @IsNotEmpty() device: IGetDeviceResponseDto =
    new GetDeviceResponseDto();
  @Expose({ name: "monitorings" })
  @IsNotEmpty()
  treatmentMonitorings: IGetTreatmentResponseDto[] = [];
  @Expose() @IsNotEmpty() sign: IGetSignResponseDto = new GetSignResponseDto();
  @Expose() @IsNotEmpty() type: string = "";
  @Expose() @IsNotEmpty() key?: string = "";
}

export class GetReadDeviceResponseDto implements IGetReadDeviceResponseDto {
  @Expose() @IsNotEmpty() id: number = 0;
  @Expose() deviceId: number =  0;
  @Expose() user: IGetPatientResponseDto = new GetPatientResponseDto();
  @Expose() takeBy: IGetUserResponseDto = new GetUserResponseDto();
  @Expose() created_at: Date = new Date();
  @Expose() type_read_device: string = '';
  @Expose() validated: boolean = false;
}

export class ItemReadDeviceListResponseDto implements IItemReadDeviceResponseDto {
  @Expose() @IsNotEmpty() id: number = 0;
  @Expose() device: IGetDeviceResponseDto = new GetDeviceResponseDto();
  @Expose() user: IGetPatientResponseDto = new GetPatientResponseDto();
  @Expose() takeBy: IGetUserResponseDto = new GetUserResponseDto();
  @Expose() created_at: Date = new Date();
  @Expose() measurementsInRead: IGetMeasurementResponseDto = new GetMeasurementResponseDto();
  @Expose() photo?: string = "";
  @Expose() type_read_device?: string = "";
}